<template>
  <div class="dashboard-parent">
    <div class="m-2 d-flex justify-content-between row-flex-align-baseline table-actions">
      <span v-if="isEditMode" class="tw-text-base tw-font-bold">{{ $t('Edit dashboard') }}</span>
      <span v-else class="tw-text-base tw-font-bold">{{ $t('New dashboard') }}</span>
      <div class="d-flex">
        <b-button
          id="search__clear-all"
          variant="outline-secondary"
          class="mr-1"
          @click="$root.$emit('widgetToggleForm', false)"
        >
          <span class="align-middle">{{ $t('Cancel') }}</span>
        </b-button>
        <b-button
          id="create-button"
          variant="primary"
          :class="{
            'icon-disabled': !$can('Create', 'Dashboard'),
          }"
          @click="saveForm"
        >
          <feather-icon
            icon="SaveIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('Save') }}</span>
        </b-button>
        <b-tooltip target="create-button">
          {{ $can('Create', 'Widget') ? $t('Create') : $t('no-action-permission') }}
        </b-tooltip>
      </div>
    </div>

    <form
      ref="form"
      autocomplete="off"
      @submit.prevent="saveForm"
    >
      <b-container
        fluid
        class="basic-detail"
      >
        <b-row
          class="tw-mb-2 tw-mt-4"
          align-v="baseline"
        >
          <b-col
            lg="4"
            cols="6"
          >
            <div>
              <prozess-input
                v-model="form.name"
                :placeholder="$t('Dashboard name')"
                field="name"
                name="name"
                :error="$hasError('name')"
                @enter="saveForm"
              />
            </div>
          </b-col>

          <b-col
            lg="4"
            cols="6"
          >
            <div>
              <v-select
                v-model="form.selectedGroup"
                :placeholder="$t('Groups')"
                :options="groups"
                label="name"
                @search="onSearchGroups"
                @search:blur="onBlurSearchGroups"
              >
                <span slot="no-options">
                  <span v-if="searchGroupText.length">{{ $t(`No results found`) }}</span>
                  <span v-else>{{ $t(`Search by name`) }}</span>
                </span>
              </v-select>
              <div class="my-1">
                <feather-icon
                  icon="InfoIcon"
                  class="text-secondary tw-mr-1"
                />
                {{ $t('info-on-group') }}
              </div>
            </div>
          </b-col>

          <b-col
            lg="4"
            cols="6"
          >
            <div>
              <prozess-field-wrapper
                :icon="MetastatusTypeIcons['category']"
                :error="$hasError('categoryId')"
              >
                <prozess-select
                  v-model="form.categoryId"
                  style="flex: 1"
                  :placeholder="$t('Category')"
                  :options="newCategoryOptions"
                  :reduce="option => option.metaStatusId"
                  label="metaStatusName"
                  :clearable="false"
                />
              </prozess-field-wrapper>
            </div>
          </b-col>
        </b-row>

        <b-row
          style="margin-bottom: 1.5em"
        >
          <b-col cols="2">
            <div class="d-flex">
              <b-form-checkbox
                v-model="form.showInCompany"
              />
              <div>{{ $t('Show In Companies') }}</div>
            </div>
          </b-col>
          <b-col cols="2">
            <div class="d-flex">
              <b-form-checkbox
                v-model="form.showInContact"
              />
              <div>{{ $t('Show In Contacts') }}</div>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-textarea
              v-model="form.description"
              :placeholder="$t('Description')"
              field="description"
              name="description"
            />
          </b-col>
        </b-row>
      </b-container>
      <span class="invalid-feedback font-weight-bold tw-mt-2">{{ $hasError('widgetRows') }}</span>
      <div
        v-for="(row, index) in form.widgetRows"
        :key="index"
        class="bg-card tw-text-body tw-mt-5 widget-creation"
        :class="{ 'widget-creation-wrap ': row.widgetUuids.length >= 3 }"
      >
        <div
          v-for="(widget, widgetIndex) in row.widgetUuids"
          :key="widgetIndex"
          class="widget-creation__form"
        >
          <prozess-field-wrapper class="flex">
            <prozess-select
              v-model="widget.selectedWidget"
              :clearable="false"
              :placeholder="$t('Widget name')"
              :options="widgets"
              label="name"
              style="width: 250px"
            />
          </prozess-field-wrapper>
          <prozess-input
            v-model="widget.selectedWidget.type"
            class="input-field"
            disabled
            :placeholder="$t('Type')"
            field="name"
            name="name"
          />
          <div
            v-if="form.widgetRows[index].widgetUuids.length > 1 || form.widgetRows.length > 1"
            class="widget-creation__form--delete-icon cursor-pointer"
            @click="deleteWidgetColumn(index, widgetIndex)"
          >
            <feather-icon
              size="15"
              icon="TrashIcon"
              square
            />
          </div>
        </div>
        <div
          style="height: 25px; width: 25px"
          class="tw-cursor-pointer tw-top-0 tw-ml-4 tw-right-0 widget-creation__form--add-column"
        >
          <feather-icon
            size="25"
            class="tw-rounded"
            style="background-color: var(--colour--secondary)"
            icon="PlusIcon"
            square
            @click="addWidgetColumn(index)"
          />
        </div>
      </div>

      <div
        class="add-row-action cursor-pointer"
        @click="addWidgetRow()"
      >
        <div class="row-flex-align-center row-flex-justify-center">
          <feather-icon
            size="13"
            class="tw-mr-2"
            icon="PlusIcon"
          />
          {{ $t('Add row for widget') }}
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { dashboardInitialForm, initialRowSet } from '@/constants/dashboard'
import { dashboardService } from '@/services/dashboard'
import { debounce } from 'lodash'
import vSelect from 'vue-select'
import { dashboardSchema } from '@/schema/dashboard'
import MetastatusTypes, { MetastatusTypeIcons } from '@/constants/metastatusTypes'
// import metastatusDropdownMixin from '@/mixins/metastatusDropdown'
import { metastatusService } from '@/services/metastatus'

export default {
  components: { vSelect },
  // mixins: [metastatusDropdownMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    isEditMode: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      MetastatusTypeIcons,
      MetastatusTypes,
      form: this.$lodash.cloneDeep(dashboardInitialForm),
      groups: [],
      widgets: [],
      searchGroupText: '',
      errors: [],
      version: null,
      categoryOptions: [],
    }
  },

  computed: {
    widgetIsValid() {
      return this.form.widgetRows.every(row => row.widgetUuids.every(col => col.selectedWidget))
    },

    savePayload() {
      const form = this.form
      const widgetRows = form.widgetRows.map(row => ({
        widgetUuids: row.widgetUuids.map(col => col.selectedWidget.uuidKey),
      }))

      return {
        name: form.name,
        description: form.description || undefined,
        categoryId: form.categoryId,
        showInCompany: form.showInCompany,
        showInContact: form.showInContact,
        groupUuid: form.selectedGroup?.uuidKey ?? undefined,
        widgetRows,
      }
    },
    newCategoryOptions() {
      return this.categoryOptions.filter(
        v => {
          const entityIndex = v.entities.findIndex(e => (e.schemaName === 'dashboard' && e.tableName === 'dashboard'))
          if (entityIndex >= 0 && !this.isEditMode && v.entities[entityIndex].isDefaultValue) {
            this.form.categoryId = v.metaStatusId
          }
          return entityIndex >= 0
        },
      )
    },
  },

  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.fetchWidgets()
        }
      },
      immediate: true,
    },
    id: {
      handler(newVal, oldVal) {
        if (newVal && newVal !== '') {
          this.setEditValues(newVal)
        }
      },
      immediate: true,
    },
  },

  destroyed() {
    this.clearAll()
  },

  async mounted() {
    const categoriesResponse = await metastatusService(MetastatusTypes.CATEGORY).getAll()
    if (categoriesResponse?.data?.pageItems) {
      this.categoryOptions = categoriesResponse.data.pageItems
    }
  },

  methods: {
    clearAll() {
      this.form = this.$lodash.cloneDeep(dashboardInitialForm)
    },

    deleteWidgetColumn(rowIndex, colIndex) {
      const widgetRows = this.form.widgetRows[rowIndex]

      if (widgetRows.widgetUuids.length <= 1) {
        this.form.widgetRows = this.form.widgetRows.filter((row, index) => index !== rowIndex)

        this.$forceUpdate()
      } else widgetRows.widgetUuids.splice(colIndex, 1)
    },
    async saveForm() {
      this.errors = await this.yupValidate(dashboardSchema, {
        ...this.form,
        widgetRows: this.widgetIsValid,
      })

      if (this.errors.length) return false

      const { response } = await this.$async(this.createUpdate())

      if (response.errors) return false

      const categorizedDashboardResponse = await dashboardService().getMyCategorizedDashboard()
      this.$store.dispatch('user/updateDashboardList', { categorizedDashboardResponse })
      this.clearAll()
      this.$root.$emit('widgetToggleForm', false)
    },

    createUpdate() {
      if (this.id) {
        Object.assign(this.savePayload, { version: this.version })
      }
      return this.id ? dashboardService('dashboard').update(this.id, this.savePayload) : dashboardService('dashboard').store(this.savePayload)
    },

    addWidgetRow() {
      this.form.widgetRows.push(this.$lodash.cloneDeep(initialRowSet))
    },

    addWidgetColumn(index) {
      const widgetRow = this.form.widgetRows[index]
      if (widgetRow.widgetUuids.length >= 4) return false

      widgetRow.widgetUuids.push({
        selectedWidget: '',
      })
    },

    onBlurSearchGroups() {
      this.groups = []
    },

    onSearchGroups(search, loading) {
      this.searchGroupText = search
      if (search.length) {
        loading(true)
        this.searchGroup(search, loading, this)
      }
    },

    searchGroup: debounce(async (search, loading, vm) => {
      const { data } = await dashboardService().searchGroup(search)
      vm.groups = data.pageItems.map(item => ({ ...item, label: item.name }))
      loading(false)
    }, 500),

    async fetchWidgets() {
      const { response: widgetResponse } = await this.$async(dashboardService('dashboard').getAllWidgetData())
      this.widgets = widgetResponse.data.pageItems
    },

    async setEditValues(id) {
      const { data } = await dashboardService('dashboard').getOne(id)
      this.form.name = data.name ?? ''
      this.form.description = data.description ?? ''
      this.version = data?.version ?? 0
      this.form.categoryId = data?.categoryId ?? null
      this.form.showInCompany = data?.showInCompany ?? false
      this.form.showInContact = data?.showInContact ?? false
      if (data?.groupInfo?.name) {
        const groupUuid = data.groupInfo.uuidKey
        const response = await dashboardService().searchGroup(data.groupInfo.name)
        const currentGroup = response.data.pageItems.map(e => ({ ...e, label: e.name })).find(e => e.uuidKey === groupUuid)
        this.form.selectedGroup = currentGroup
      }
      this.fetchWidgets()
      const currentWidgets = []
      if ((data?.widgetIds?.length ?? 0) > 0) {
        data.widgetIds.forEach(widgetRow => {
          const currentWidgetUuids = {
            widgetUuids: [],
          }
          if ((widgetRow?.length ?? 0) > 0) {
            widgetRow.forEach(widgetUuid => {
              const selectedWidget = {
                selectedWidget: this.widgets.find(fe => fe.uuidKey === widgetUuid.uuidKey),
              }
              currentWidgetUuids.widgetUuids.push(selectedWidget)
            })
          }
          currentWidgets.push(currentWidgetUuids)
        })
        this.form.widgetRows = currentWidgets
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/master-variables.scss';

$mobile-breakpoint: 958px;
$tablet-breakpoint: 1200px;

.dashboard-parent {
  min-height: 70vh;
  form {
    > div:not(:last-child) {
      > * {
        color: var(--colour--text-primary);
      }
      border-radius: 5px;
      padding: 20px;
    }
    padding: 15px;
    margin-top: 15px;
    .basic-detail {
      background: var(--colour--card-bg);
    }
    .widget-creation {
      position: relative;
      display: flex;
      padding: 0 25px 0 0 !important;
      &__form {
        background: var(--colour--card-bg);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin: 10px;
        margin-left: 0;
        padding: 0 10px 0 10px;
        border-radius: 15px;
        height: 100%;
        min-height: 200px;
        border: 2px dashed var(--colour--white);
        .input-field {
          width: 100%;
          margin: 10px;
          max-width: 250px;
        }

        &--delete-icon {
          position: absolute;
          top: 10px;
          right: 10px;
          background-color: var(--colour--text-muted);
          color: var(--colour--white);
          border-radius: 5px;
          padding: 5px;
        }

        &--add-column {
          position: absolute;
          right: 8px;
          top: 8px;
        }
      }
    }

    .add-row-action {
      margin: 15px 0;
      padding: 10px 0;
      color: var(--colour--primary);
      font-weight: 700;
      border-radius: 5px;
      border: 1px dashed var(--colour--input-border);
    }
  }

  .widget-creation__form {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  @media (max-width: $tablet-breakpoint) {
    .widget-creation-wrap {
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      .widget-creation__form {
        width: 47%;
        margin-bottom: 12px !important;
      }
    }
  }

  @media (max-width: $mobile-breakpoint) {
    .widget-creation,
    .widget-creation-wrap {
      display: flex;
      flex-direction: column;
      &__form {
        width: 96% !important;
      }
    }
  }
}
</style>
