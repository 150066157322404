import * as yup from 'yup'
import { customFieldValidations } from '@/constants/customFieldTypes'
import { AdvancedSearchCondition, nullableConditions } from '@/constants/advancedSearch'

export const searchModelSchema = fields => yup.object().shape({
  ...fields.reduce((schema, field) => {
    const prefix = `${field.entitySchema}_${field.entityKey}_${field.key}`

    schema[`${prefix}_condition`] = yup.string().nullable().required()

    const validation = customFieldValidations(yup)[field.type]

    if (validation && !nullableConditions.includes(AdvancedSearchCondition[field.filterCondition])) {
      schema[`${prefix}_input`] = validation().required()
    }

    return schema
  }, {}),
})

export const saveDataSourceSchema = yup.object().shape({
  name: yup.string().required().max(100, 'Max 100 limit'),
  key: yup.string().required().max(50, 'Max 50 limit'),
})
