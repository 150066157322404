<template>
    <div>
        <div class="chart-container">
          <b-table v-if="widget.widgetConfiguration.type === 'TABLE'"
            ref="refTable"
            :items="this.widgetData.result.pageItems"
            :fields="columns"
            primary-key="id"
            show-empty
            :empty-text="$t('No matching records found')"
            :class="{'table-responsive' : !isShared}"
            @sort-changed="handleSortChange"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
              </div>
            </template>

            <template #head()="{ label }">
              <span style="white-space: nowrap">{{ $t(label) }}</span>
            </template>

            <template #cell(title)="{ item }">
              <span class="text-nowrap">
                {{ item.name }}
              </span>
            </template>
          </b-table>
            <kpi-chart v-if="widget.widgetConfiguration.type === 'KPI' && widgetData != null"
              :chartData=widgetData
            />
            <div
              v-if="widget.widgetConfiguration.type !== 'KPI' && widget.widgetConfiguration.type !== 'TABLE'"
              class="graph-container"
            >
            <prozess-chart :type="widget.widgetConfiguration.type" :chart-data="widgetData" />
          </div>
        </div>
    </div>
  </template>
  <script>
import { dashboardService } from '@/services/dashboard'
import KpiChart from '@/components/shared/KpiChart.vue'
import ProzessChart from '@/components/chart/ProzessChart.vue'
import WidgetDataSourceTable from '@/components/Widgets/WidgetDataSourceTable.vue'

export default {

    components: {
        KpiChart,
        ProzessChart,
        WidgetDataSourceTable,
  },
  props: {
        widgetUuid: {
        type: String,
        required: true,
        },
    },
  data() {
    return {
        datasource: {},
        widget: {},
        widgetData: null,
    }
  },

 async mounted() {
    const { data } = await dashboardService('widget').getOne(this.widgetUuid)
    this.widget = data
    const datasourceRes = await dashboardService('datasource').getOne(this.widget.widgetConfiguration.datasourceUuidKey)
    this.datasource = datasourceRes.data

    const widgetData = await dashboardService('widget').getWidgetData(this.widgetUuid, {})
    console.log('widgetData', widgetData)
    this.widgetData = widgetData.data
    // this.widgetData = JSON.parse('{"uuidKey":"1c2be405-2aac-46fa-bd29-c419208c46af","name":"animals line","description":null,"type":"LINE","filters":null,"result":[{"description":"bear","rowItems":[{"count":8,"year":2017},{"count":54,"year":2018},{"count":93,"year":2019},{"count":116,"year":2020},{"count":137,"year":2021},{"count":184,"year":2022}]},{"description":"dolphin","rowItems":[{"count":150,"year":2017},{"count":77,"year":2018},{"count":32,"year":2019},{"count":11,"year":2020},{"count":6,"year":2021},{"count":1,"year":2022}]},{"description":"whale","rowItems":[{"count":80,"year":2017},{"count":54,"year":2018},{"count":100,"year":2019},{"count":76,"year":2020},{"count":93,"year":2021},{"count":72,"year":2022}]}]}')
  },
  methods: {
  },
}
  </script>

  <style lang="scss">
  </style>
