<template>
  <div class="column__content widget-column">
    <div v-if="selectedType">
      <div
        v-if="selectedType === 'KPI' || selectedType === 'TABLE'"
        class="widget-column__header tw-text-sm text-bold text-white"
      >
        {{ $t('Widget Value') }}
      </div>
      <div class="widget-column__body">
        <div v-if="selectedType === 'KPI'">
          <prozess-select
            v-model="form.type"
            class="tw-pt-2"
            :options="columnsWithAggregation"
            :placeholder="$t('Field Name')"
          />
          <span class="text-muted">{{ $t('Only field with aggregation is available') }}</span>
        </div>
        <div v-if="selectedType === 'TABLE'">
          <prozess-input
            v-model="form.description"
            field="table"
            :placeholder="$t('Description')"
          />
        </div>

        <div v-if="selectedType !== 'KPI' && selectedType !== 'TABLE'">
          <WidgetGraphForm :chart-type="selectedType" />
        </div>
      </div>
    </div>
    <div
      v-else
      class="tw-text-lg tw-text-center tw-text-muted tw-mt-5"
    >
      {{ $t('No Widget Value') }}
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import WidgetGraphForm from '@/components/Widgets/WidgetGraphForm.vue'

const initialFormValue = {
  type: null,
  description: null,
}

export default {
  components: { WidgetGraphForm },
  data() {
    return {
      form: this.$lodash.cloneDeep(initialFormValue),
    }
  },

  computed: {
    ...mapState({
      columns: ({ widget }) => widget.columns,
      widgetValue: ({ widget }) => widget.widgetValue,
      selectedType: ({ widget }) => widget.selectedWidget,
      widgetConfiguration: ({ widget }) => { widget.widgetConfiguration },
    }),
    ...mapGetters('widget', ['columnsWithAggregation']),
  },

  watch: {
    selectedType: {
      handler(newVal, oldVal) {
        // eslint-disable-next-line eqeqeq
        if (newVal != this.widgetConfiguration?.widgetConfiguration?.type) {
          this.form = this.$lodash.cloneDeep(initialFormValue)
        } else {
          this.setFormData()
        }
      },
      immediate: true,
    },

    widgetValue: {
      handler(newVal, oldVal) {
        this.form = newVal
      },
      immediate: true,
    },

    form: {
      handler(newVal, oldVal) {
        this.$store.commit('widget/updateWidgetValue', newVal)
      },
      deep: true,
    },
  },

  methods: {
    setFormData() {
      if (this?.widgetConfiguration?.widgetConfiguration?.type === 'KPI') {
        const field = this.widgetConfiguration.widgetConfiguration?.singleNumberConfiguration?.field
        if (field != null) {
          const kpiCurrentValueIndex = this.columnsWithAggregation.findIndex(
            e => e.key === `${field.schemaKey}.${field.tableKey}.${field.fieldKey}`,
          )
          const kpiCurrentValue = kpiCurrentValueIndex < 0 ? null : this.columnsWithAggregation[kpiCurrentValueIndex]
          this.form.type = kpiCurrentValue
        }
      } else if (this?.widgetConfiguration?.widgetConfiguration?.type === 'TABLE') {
        this.form.description = this.widgetConfiguration.description ?? null
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/master-variables.scss';

.widget-column {
  &__header {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--colour--input-border);
  }
}
</style>
