<template>
  <b-row>
    <b-col v-for="field in inputFields" :key="field.key" md="6" cols="12">
      <prozess-input
        v-model="field.val"
        :placeholder="$t(field.label)"
        :field="field.key"
        :name="field.key"
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      inputFields: [],
    }
  },
  computed: {
    ...mapState('widget', ['previewData']),
    getAllUserAccessible() {
      return (
        this.previewData?.filters?.filter(col => col.isParamUserAccessible)
        ?? []
      )
    },
  },

  watch: {
    getAllUserAccessible: {
      handler(newVal) {
        if (this.inputFields.length) return false

        this.inputFields = newVal.length
          ? newVal.map(val => ({
            fieldInfo: val.field,
            label: this.$case.title(this.$t(val.field.fieldKey)),
            val: val.paramValue,
            key: val.field.fieldKey,
          }))
          : []
      },
      immediate: true,
    },

    inputFields: {
      handler(newVal) {
        this.$store.commit('widget/setPreviewUserFilterValues', newVal)
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>

<style></style>
