<template>
  <div class="column__content">
    <div
      v-show="!isPreviewing"
      class="tw-text-lg tw-text-center tw-text-muted tw-mt-5"
    >
      {{ $t('No Widget Loaded') }}
    </div>

    <div v-show="isPreviewing" class="chart-container">
      <user-filter-input />
      <kpi-chart v-if="selectedWidget === 'KPI'" />
      <div v-if="selectedWidget === 'TABLE'">
        <widget-data-source-table
          v-show="!loadingDataSource"
          ref="previewTable"
          :for-preview="true"
        />

        <div v-show="loadingDataSource">
          {{ $t('Getting data, please wait...') }}
        </div>
      </div>

      <div
        v-if="
          previewData &&
            selectedWidget !== 'KPI' &&
            selectedWidget !== 'TABLE'
        "
        class="graph-container"
      >
        <div class="graph-container__title">
          {{ title($t(selectedWidget.toLowerCase() + ' Chart')) }}
        </div>

        <prozess-chart :type="selectedWidget" :chart-data="previewData" />
      </div>
    </div>
  </div>
</template>

<script>
import UserFilterInput from '@/components/Widgets/UserFilterInput.vue'
import ProzessChart from '@/components/chart/ProzessChart.vue'
import KpiChart from '@/components/shared/KpiChart.vue'
import { mapState } from 'vuex'
import RootEvents from '@/constants/rootEvents'
import WidgetDataSourceTable from '@/components/Widgets/WidgetDataSourceTable.vue'
import { title } from '@/@core/utils/filter'
import { dataTable } from '@/mixins/dashboard'

export default {
  components: {
    ProzessChart,
    KpiChart,
    WidgetDataSourceTable,
    UserFilterInput,
  },
  mixins: [dataTable],
  data() {
    return {
      title,
      loadingDataSource: false,
    }
  },
  computed: {
    ...mapState({
      form: ({ widget }) => widget.baseData,
      isPreviewing: ({ widget }) => widget.isPreviewing,
      selectedWidget: ({ widget }) => widget.selectedWidget,
      previewData: ({ widget }) => widget.previewData,
    }),
  },

  watch: {
    isPreviewing: {
      async handler(newVal) {
        if (newVal && this.selectedWidget === 'TABLE') {
          this.loadingDataSource = true
          this.$root.$emit(RootEvents.CALL_API, 0, true)
          this.loadingDataSource = false
        }
      },
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/master-variables.scss';
.column__content {
  .chart-container {
    margin-top: 10px;
  }
  .graph-container {
    &__title {
      color: var(--colour--white);
      font-size: 1.8rem;
      padding-bottom: 5px;
      margin-bottom: 15px;
      border-bottom: 2px solid var(--colour--input-border);
    }
  }
}
</style>
