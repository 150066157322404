<template>
  <div class="widget-parent">
    <form
      ref="form"
      autocomplete="off"
      @submit.prevent
    >
      <div
        v-if="step !== 3"
        class="widget-form"
      >
        <div class="widget-form__header bg-card tw-text-body">
          <span v-if="!isEditMode" class="tw-text-base tw-font-bold">{{ $t('New Widget') }}</span>
          <span v-else class="tw-text-base tw-font-bold">{{ $t('Edit Widget') }}</span>
          <b-container fluid>
            <b-row
              class="tw-mb-2 tw-mt-4"
              align-v="baseline"
            >
              <b-col
                lg="4"
                cols="6"
              >
                <div>
                  <prozess-input
                    v-model="form.name"
                    :placeholder="$t('Widget name')"
                    field="name"
                    name="name"
                  />
                </div>
              </b-col>
              <b-col
                lg="4"
                cols="6"
              >
                <div>
                  <v-select
                    v-model="form.dataSource"
                    :placeholder="$t('Data Source')"
                    :options="dataSources"
                    :get-option-label="option => option.name"
                    @input="selectDataSource"
                    @search="onSearchDataSource"
                    @search:blur="onBlurDataSourceSearch"
                  >
                    <span slot="no-options">
                      <span v-if="searchDataSourceText.length">{{ $t(`No results found`) }}</span>
                      <span v-else>{{ $t(`Search by name`) }}</span>
                    </span>
                  </v-select>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-textarea
                  v-model="form.description"
                  :placeholder="$t('Description')"
                  field="description"
                  name="description"
                />
              </b-col>
            </b-row>
          </b-container>
        </div>

        <!-- Start: DATA SOURCE STEP 2 -->

        <div
          v-if="step === 2 && form.dataSource"
          class="widget-form__body bg-card tw-text-body tw-mt-5"
        >
          <span class="tw-text-base tw-font-bold">{{ $t('Data Sources') }}</span>

          <WidgetDataSourceTable v-show="!loadingDataSource" />
          <div v-show="loadingDataSource">
            {{ $t('Getting data, please wait...') }}
          </div>
        </div>

        <!-- End: DATA SOURCE STEP 2 -->
      </div>

      <!-- START: Form action -->

      <div
        v-show="step !== 3"
        class="widget-action row-flex-justify-end row-flex-align-center tw-mt-4"
      >
        <b-button
          v-if="step !== 3"
          variant="primary"
          class="tw-mr-4"
          :class="{ 'icon-disabled': !form.dataSource || !form.name }"
          @click="form.dataSource && form.name && runNext()"
        >
          <span class="align-middle">{{ $t('Next') }}</span>
        </b-button>

        <b-button
          variant="outline-secondary"
          @click="clearAll"
        >
          <span class="align-middle">{{ $t('Cancel') }}</span>
        </b-button>
      </div>

      <!-- END: Form action -->

      <!-- Start: Data Source Step 3 -->
      <div v-if="isAdvancedDatasourceStep3(form, step)">
        <WidgetAdvancedDataCreation :form="form" :isEditMode="isEditMode" :id="id"/>
      </div>
      <div v-else-if="step === 3">
        <WidgetChartCreation @close="step = 2" />
      </div>
      <!-- End:  Data Source Step 3 -->
    </form>
  </div>
</template>

<script>
import WidgetChartCreation from '@/components/Widgets/WidgetChartCreation.vue'
import WidgetAdvancedDataCreation from '@/components/Widgets/WidgetAdvancedDataCreation.vue'
import WidgetDataSourceTable from '@/components/Widgets/WidgetDataSourceTable.vue'
import { dashboardService } from '@/services/dashboard'
import RootEvents from '@/constants/rootEvents'
import { dataTable } from '@/mixins/dashboard'
import vSelect from 'vue-select'
import { debounce } from 'lodash'
import { mapMutations, mapState } from 'vuex'

const initialForm = {
  name: '',
  dataSource: null,
  description: null,
}
export default {
  name: 'WidgetForm',
  components: { vSelect, WidgetDataSourceTable, WidgetChartCreation, WidgetAdvancedDataCreation },
  mixins: [dataTable],
  props: {
    id: {
      type: String,
      required: true,
    },

    isViewing: {
      type: Boolean,
      default: false,
    },

    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      step: 1,
      form: this.$lodash.cloneDeep(initialForm),
      sampledata: [
        {
          color: null,
          name: 'test1',
        },
        {
          color: null,
          name: 'test2',
        },
        {
          color: null,
          name: 'test3',
        },
      ],
      dataSources: [],
      searchDataSourceText: '',
      loadingDataSource: false,
    }
  },

  computed: {
    ...mapState('widget', ['baseData']),
  },

  watch: {
    form: {
      handler(newVal, oldVal) {
        this.setBaseData(newVal)
      },
      immediate: true,
    },

    baseData: {
      handler(newVal, oldVal) {
        this.form = newVal
      },
      immediate: true,
    },

    step: {
      handler(newVal, oldVal) {
        if (newVal !== 3) {
          this.$store.commit('widget/updateSelectedWidget', null)
        }
        if (newVal === 1) {
          this.form.dataSources = null
          this.searchDataSourceText = ''
        }

        if (newVal === 2) {
          this.processStep2()
        }
      },
    },
  },

  destroyed() {
    this.clearAll()
  },

  async mounted() {
    this.setWidgetConfiguration(null)
    if (this.isEditMode && this.id != null && this.id.length > 0) {
      const result = await dashboardService('widget').getOne(this.id)
      this.form.name = result.data?.name ?? null
      this.form.description = result.data?.description ?? null
      this.form.widget = result.data
      Object.assign(result.data, { widgetId: this.id })
      this.setWidgetConfiguration(result.data)
      const { data } = await dashboardService('datasource').getOne(result.data.widgetConfiguration.datasourceUuidKey)
      this.selectDataSource(data)
      this.step = 2
    }
    this.registerBusEvent(RootEvents.WIDGET_CLEAR_ALL, () => {
      this.form = this.$lodash.cloneDeep(initialForm)
      this.step = 1
      this.resetForm()
      this.$root.$emit(RootEvents.WIDGET_TOGGLE_FORM, {
        show: false,
      })
    })
  },

  methods: {
    ...mapMutations({
      setBaseData: 'widget/SET_BASE_DATA',
      resetForm: 'widget/resetForm',
      setWidgetConfiguration: 'widget/setWidgetConfiguration',
    }),
    isAdvancedDatasourceStep3(form, step) {
        return step === 3 && (form.dataSource.isAdvanced || form.dataSource.query != null)
    },
    async processStep2() {
      this.loadingDataSource = true

      if (!this.form.dataSource) return

      this.getDataSource()
      this.loadingDataSource = false
    },

    onBlurDataSourceSearch() {
      this.dataSources = []
    },

    onSearchDataSource(search, loading) {
      this.searchDataSourceText = search
      if (search.length) {
        loading(true)
        this.searchDataSource(search, loading, this)
      }
    },

    selectDataSource(item) {
      this.step = 1
      this.form.dataSource = item
    },

    searchDataSource: debounce(async (search, loading, vm) => {
      const { data } = await dashboardService('datasource').searchDataSource(search)
      vm.dataSources = data.pageItems
      loading(false)
    }, 500),

    clearAll() {
      this.$root.$emit(RootEvents.WIDGET_CLEAR_ALL)
    },

    runNext() {
      this.step += 1
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/master-variables.scss';
.widget-parent {
  form {
    min-height: 70vh;
  }
  .widget-form {
    > div {
      > * {
        color: var(--colour--text-primary);
      }
      border-radius: 5px;
      padding: 20px;
      background: var(--colour--card-bg);
    }

    &__body {
      min-height: 500px;
    }
  }
}
</style>
