<template>
  <b-modal
    id="advanced-search__save-modal"
    v-model="open"
    hide-footer
    centered
    no-fade
    size="lg"
  >
    <div class="modal__body tw-p-8">
      <div class="modal__header d-flex align-items-center tw-mb-6">
        <h4 class="mb-0">
          {{ title }}
        </h4>
      </div>

      <b-overlay
        :show="loading"
        spinner-variant="primary"
        class="d-flex flex-column"
        variant="transparent"
        style="flex: 1"
      >
        <div class="search__form">
          <div class="mb-2">
            <prozess-input
              v-model="form.name"
              :placeholder="$t('Name')"
              field="name"
              :error="$hasError('name')"
              :hint="`*${$t('Required')}`"
            />
          </div>
          <b-form-textarea
            v-model="form.description"
            :placeholder="$t('Description')"
            rows="3"
          />
        </div>
        <div class="d-flex justify-content-end align-items-center tw-mt-6">
          <button
            class="btn btn-dark tw-ml-3"
            type="button"
            @click="close"
          >
            {{ $t('Cancel') }}
          </button>
          <button
            v-if="isEditMode"
            class="btn btn-secondary tw-ml-3"
            type="button"
            @click="handleSaveClick($route.params.queryType)"
          >
            {{ $t('Save changes') }}
          </button>
          <template v-else>
            <button
              v-if="canSavePersonal"
              class="btn btn-secondary tw-ml-3"
              type="button"
              @click="handleSaveClick('personal')"
            >
              {{ $t('Save as Personal') }}
            </button>
            <button
              v-if="canSaveGlobal"
              class="btn btn-primary tw-ml-3"
              type="button"
              @click="handleSaveClick('global')"
            >
              {{ $t('Save as Global') }}
            </button>
          </template>
        </div>
      </b-overlay>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { saveSearchSchema } from '@/schema/advancedSearch'
import RootEvents from '@/constants/rootEvents'
import advancedSearchService from '@/services/advancedSearch'

const defaultForm = {
  name: '',
  description: '',
}

export default {
  data() {
    return {
      open: false,
      loading: false,
      errors: [],
      form: this.$lodash.cloneDeep(defaultForm),
    }
  },

  computed: {
    ...mapGetters({
      searchModel: 'advancedSearch/searchModel',
    }),

    isEditMode() {
      return !!this.$route.params.id
    },

    title() {
      return this.$route.params.id ? this.$t('Update Search Query') : this.$t('Save Search Query')
    },

    canSaveGlobal() {
      return this.$can('Create', 'Global_Query')
    },

    canSavePersonal() {
      return this.$can('Create', 'Personal_Query')
    },
  },

  mounted() {
    this.registerBusEvent(RootEvents.ADV_SEARCH_CONFIRM_SAVE, ({ open, data }) => {
      this.open = open ?? true
      this.loading = false

      const form = this.$route.params.id ? data : this.$lodash.cloneDeep(defaultForm)

      this.form = {
        ...form,
        searchModel: this.searchModel,
      }
    })
  },

  destroyed() {
    this.$root.$off(RootEvents.ADV_SEARCH_CONFIRM_SAVE)
  },

  methods: {
    close() {
      this.open = false
    },

    async handleSaveClick(type) {
      this.form.name = this.form.name.trim()

      this.errors = await this.yupValidate(saveSearchSchema, this.form)

      if (this.errors.length > 0) {
        return false
      }

      this.loading = true

      const request = this.$route.params.id
        ? advancedSearchService.updateByType(type, this.$route.params.id, this.form)
        : advancedSearchService.saveByType(type, this.form)

      const { response } = await this.$async(request)

      this.loading = false

      if (response) {
        this.$router.push({ name: 'advanced-search' })
      }
    },
  },
}
</script>
