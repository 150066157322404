import lodash from 'lodash'

export const initialRowSet = {
  description: '',
  widgetUuids: [{
    selectedWidget: '',
  }],
}

export const dashboardInitialForm = {
  name: '',
  selectedGroup: '',
  description: '',
  categoryId: null,
  showInCompany: false,
  showInContact: false,
  widgetRows: [
    lodash.cloneDeep(initialRowSet),
  ],
}
