<template>
  <b-modal
    id="advanced-search__save-modal"
    v-model="open"
    hide-footer
    centered
    no-fade
    size="sm"
  >
    <div class="modal__body tw-p-8">
      <div class="modal__header d-flex align-items-center tw-mb-6">
        <h4 class="mb-0">
          {{ title }}
        </h4>
      </div>

      <b-overlay
        :show="loading"
        spinner-variant="primary"
        class="d-flex flex-column"
        variant="transparent"
        style="flex: 1"
      >
        <div class="search__form">
          <div class="mb-2">
            <prozess-input
              v-model="form.name"
              :placeholder="$t('Name')"
              field="name"
              :error="$hasError('name')"
              :hint="`*${$t('Required')}`"
            />
          </div>
          <prozess-input
            v-model="form.key"
            :readonly="!!id"
            :placeholder="$t('Key')"
            field="key"
            :error="$hasError('key')"
            :hint="$t('The field key must be unique')"
            @keypress="preventSpecialChars"
            @input="keyFieldTouched = true"
          />
        </div>
        <div class="d-flex justify-content-center align-items-center tw-mt-6">
          <button
            v-if="isEditMode"
            class="btn btn-secondary tw-ml-3"
            type="button"
            @click="handleSaveClick"
          >
            {{ $t('Save changes') }}
          </button>
          <template v-else>
            <button
              class="btn btn-secondary tw-ml-3"
              type="button"
              @click="handleSaveClick"
            >
              {{ $t('Save') }}
            </button>
          </template>

          <button
            class="btn btn-dark tw-ml-3"
            type="button"
            @click="close"
          >
            {{ $t('Cancel') }}
          </button>
        </div>
      </b-overlay>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { saveDataSourceSchema } from '@/schema/dataSource'
import { dashboardService } from '@/services/dashboard'
import { preventSpecialChars } from '@/helpers/app'
import RootEvents from '@/constants/rootEvents'

const defaultForm = {
  name: '',
  key: '',
}

export default {
  data() {
    return {
      open: false,
      loading: false,
      errors: [],
      keyFieldTouched: false,
      form: this.$lodash.cloneDeep(defaultForm),
    }
  },

  computed: {
    ...mapGetters({
      searchModel: 'advancedSearch/searchModel',
    }),

    ...mapState({
      id: ({ dashboard }) => dashboard.id,
    }),

    isEditMode() {
      return !!this.id
    },

    title() {
      return this.id ? this.$t('Updating Source') : this.$t('Saving Source')
    },
  },

  watch: {
    'form.name': function (value) {
      // if (this.resource.id) return
      if (!this.keyFieldTouched && !this.id) {
        const replaceSpecialChar = value?.replace(/[^\w\s]/gi, '') ?? ''
        this.form.key = this.$case.snake(replaceSpecialChar)
      }
    },
  },

  mounted() {
    this.registerBusEvent(RootEvents.ADV_SEARCH_CONFIRM_SAVE, ({ open, data }) => {
      this.open = open ?? true
      this.loading = false

      const form = this.id ? { name: data.name, key: data.description, version: data.version } : this.$lodash.cloneDeep(defaultForm)

      this.form = {
        ...form,
        configuration: this.searchModel,
      }
    })
  },

  destroyed() {
    this.$root.$off(RootEvents.ADV_SEARCH_CONFIRM_SAVE)
  },

  methods: {
    preventSpecialChars(e) {
      return preventSpecialChars(e, {
        callback: data => {
          this.resource.key = data
          this.$forceUpdate()
        },
      })
    },

    close() {
      this.open = false
      this.errors = []
    },

    async handleSaveClick() {
      this.form.name = this.form.name.trim()

      this.errors = await this.yupValidate(saveDataSourceSchema, this.form)

      if (this.errors.length > 0) {
        return false
      }

      this.loading = true

      const request = this.id ? dashboardService('datasource').update(this.id, this.form) : dashboardService('datasource').store(this.form)

      const { response } = await this.$async(request)

      this.loading = false

      if (response) {
        this.$store.commit('dashboard/SET_DATASOURCE_FORM_VISIBILITY', false)
      }
    },
  },
}
</script>
