<template>
  <div class="bar-graph-container">
    <div class="widget-column__header tw-text-sm text-bold text-white">
      {{ $t('X-axis') }}
    </div>

    <prozess-select
      v-model="form.field"
      :clearable="false"
      :options="columns"
      :placeholder="$t('Field Name')"
    />
    <prozess-input
      v-model="form.name"
      field="name"
      :placeholder="$t('Legend Name')"
    />

    <div class="widget-column__header tw-text-sm text-bold text-white">
      {{ $t('Values') }}
    </div>
    <div
      v-for="(value, index) in form.values"
      :key="index"
      class="tw-mb-2"
    >
      <prozess-select
        v-model="value.value"
        :clearable="false"
        :options="columnsWithAggregation"
        :placeholder="$t('Value')"
      />
      <span class="text-muted">{{ $t('Only field with aggregation is available') }}</span>
      <prozess-input
        v-model="value.name"
        field="name"
        :placeholder="$t('Legend Name')"
        class="tw-mt-2"
      />

      <div
        class="row-flex-align-center row-flex-justify-center text-muted cursor-pointer"
        @click="deleteValue(index)"
      >
        <feather-icon
          icon="TrashIcon"
          class="tw-mr-4"
        />
        <span>
          {{ $t('Delete this value') }}
        </span>
      </div>
    </div>

    <div
      v-if="chartType != 'PIE' && chartType != 'TREE'"
      class="row-flex-align-center row-flex-justify-center text-white tw-text-base cursor-pointer tw-mt-6"
      @click="addNewValue"
    >
      <feather-icon
        icon="PlusCircleIcon"
        class="tw-mr-4"
      />
      <span>
        {{ $t('Add new value') }}
      </span>
    </div>

    <b-button
      variant="outline-secondary"
      class="tw-mr-4"
      @click="clearForm"
    >
      <span class="align-middle">{{ $t('Clear') }}</span>
    </b-button>
  </div>
</template>

<script>
/* eslint-disable eqeqeq */
import { mapState, mapGetters } from 'vuex'

const initialValues = { value: '', name: '' }
const initialForm = {
  field: '',
  name: '',
  values: [{ ...initialValues }],
}

export default {
  props: {
    chartType: {
      type: String,
      default: 'BAR',
      required: false,
    },
  },
  data() {
    return {
      form: this.$lodash.cloneDeep(initialForm),
    }
  },
  computed: {
    ...mapState({
      columns: ({ widget }) => widget.columns,
      selectedType: ({ widget }) => widget.selectedWidget,
      widgetConfiguration: ({ widget }) => widget.widgetConfiguration,
    }),
    ...mapGetters('widget', ['columnsWithAggregation']),
  },

  watch: {
    selectedType: {
      handler(newVal, oldVal) {
        if (newVal != this.widgetConfiguration?.widgetConfiguration?.type) {
          this.form = this.$lodash.cloneDeep(initialForm)
        } else {
          this.setFormData()
        }
      },
      immediate: true,
    },

    form: {
      handler(newVal, oldVal) {
        this.$store.commit('widget/setChartConfig', newVal)
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    setFormData() {
      if (!(this?.widgetConfiguration?.widgetConfiguration)) {
        // eslint-disable-next-line no-useless-return
        return
      }
      if (this.widgetConfiguration.widgetConfiguration?.countLabelConfiguration?.labelField) {
        const wcLabelField = this.widgetConfiguration.widgetConfiguration.countLabelConfiguration.labelField
        const currentColumnIndex = this.columns.findIndex(
          e => e.key === `${wcLabelField.schemaKey}.${wcLabelField.tableKey}.${wcLabelField.fieldKey}`,
        )
        if (currentColumnIndex >= 0) {
          this.form.field = this.columns[currentColumnIndex]
        }
      }
      if ((this?.widgetConfiguration?.widgetConfiguration?.countLabelConfiguration?.countConfigurations.length ?? 0) > 0) {
        const currentValues = []
        this.widgetConfiguration.widgetConfiguration.countLabelConfiguration.countConfigurations.forEach(
          e => {
            const countField = `${e.countField.schemaKey}.${e.countField.tableKey}.${e.countField.fieldKey}`
            const fieldAggregationIndex = this.columnsWithAggregation.findIndex(element => element.key === countField)
            if (fieldAggregationIndex >= 0) {
              const selectedFieldAggregation = this.columnsWithAggregation[fieldAggregationIndex]
              const currentValue = {
                value: selectedFieldAggregation,
                name: e?.description ?? null,
              }
              currentValues.push(currentValue)
            }
        })
        if (currentValues.length > 0) {
          this.form.values = currentValues
        }
      }
    },
    clearForm() {
      this.form = this.$lodash.cloneDeep(initialForm)
    },
    addNewValue() {
      this.form.values.push({ ...initialValues })
    },

    deleteValue(index) {
      if (this.form.values.length === 1) return false
      this.form.values = this.form.values.filter((value, valIndex) => valIndex !== index)
    },
  },
}
</script>

<style lang="scss">
.bar-graph-container {
  display: flex;
  flex-direction: column;
  > div {
    > * {
      margin-bottom: 10px;
    }
  }
}
</style>
