<template>
  <div class="column__content tw-mt-5">
    <div class=" tw-text-white">
      <span class="tw-text-xs tw-ml-2">{{ $t('Select Widget type') }}</span>
      <b-form-group v-slot="{ ariaDescribedby }" class="tw-mt-4">
        <b-form-radio-group
          :aria-describedby="ariaDescribedby"
          class="checkbox-list-container__checkbox-container"
        >
          <b-form-radio
            v-for="type in types"
            :key="type.value"
            v-model="currentChartType"
            :value="type"
            field="selectedUser"
            class="tw-my-2"
            @input="updateSelectedType"
          >
            <feather-icon :icon="type.icon" class="tw-mr-2" />
            {{ $t(type.label) }}
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import { widgetTypes } from '@/constants/widgets'
import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      types: this.$lodash.cloneDeep(widgetTypes),
      currentChartType: null,
    }
  },

  computed: {
    ...mapState({
      selectedType: ({ widget }) => widget.selectedWidget,
      widgetConfiguration: state => state.widget.widgetConfiguration,
    }),
  },

  mounted() {
    if (this.widgetConfiguration?.widgetConfiguration?.type != null) {
      this.currentChartType = widgetTypes[widgetTypes.findIndex(e => e.value === this.widgetConfiguration?.widgetConfiguration?.type)]
    }
  },

  methods: {
    ...mapMutations({
      updateWidget: 'widget/updateSelectedWidget',
    }),
    updateSelectedType(e) {
      this.updateWidget(e.value)
    },
  },
}
</script>

<style></style>
