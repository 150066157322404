export default {
  methods: {
    scrollToRef(refKey, offset = 0) {
      const element = this.$refs[refKey]

      const top = element.offsetTop - offset

      window.scrollTo({
        top,
        behavior: 'smooth',
      })
    },

  },
}
