<template>
  <div class="widget-chart-container">
    <div
      class="widget-chart-container__header row-flex-justify-end row-flex-align-center"
    >
      <div class="widget-chart-container__header-action row-flex-align-center">
        <b-button variant="outline-secondary" class="tw-mr-4" @click="close">
          <span class="align-middle">{{ $t('Back') }}</span>
        </b-button>

        <b-button
          v-if="!isSaved"
          variant="primary"
          :class="{ 'icon-disabled': !formValid || loading }"
          @click="!loading && handleSave()"
        >
          <span class="align-middle">{{
            $t(loading ? 'loading-save-placeholder' : 'Save')
          }}</span>
        </b-button>
        <div v-else class="tw-pr-4 row-flex-align-center">
          <b-button
            variant="primary"
            class="tw-mx-2"
            @click="handlePreviewData(), setChartData()"
          >
            <span class="align-middle">{{ $t('Preview') }}</span>
          </b-button>

          <b-button variant="primary" @click="handleDone">
            <span class="align-middle">{{ $t('Done') }}</span>
          </b-button>
        </div>
      </div>
    </div>

    <b-container class="widget-form-container" fluid>
      <b-row>
        <b-col xl="3">
          <div>
            <span v-if="widgetConfiguration?.widgetConfiguration != null" class="tw-font-bold tw-text-sm">{{ $t('Edit Widget') }}</span>
            <span v-else class="tw-font-bold tw-text-sm">{{ $t('New Widget') }}</span>
            <WidgetColumnType />
          </div>
        </b-col>

        <b-col xl="3">
          <div>
            <span class="tw-font-bold tw-text-sm">{{ $t('Values') }}</span>
            <WidgetColumnValue />
          </div>
        </b-col>

        <b-col xl="6">
          <div>
            <span class="tw-font-bold tw-text-sm">{{ $t('Preview') }}</span>
            <WidgetColumnPreview />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import WidgetColumnType from '@/components/Widgets/WidgetColumnType.vue'
import WidgetColumnPreview from '@/components/Widgets/WidgetColumnPreview.vue'
import WidgetColumnValue from '@/components/Widgets/WidgetColumnValue.vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { dashboardService } from '@/services/dashboard'
import RootEvents from '@/constants/rootEvents'

const initialForm = {
  widgetType: null,
  values: null,
}

export default {
  components: { WidgetColumnValue, WidgetColumnPreview, WidgetColumnType },
  data() {
    return {
      form: this.$lodash.cloneDeep(initialForm),
      loading: false,
    }
  },

  computed: {
    ...mapState({
      isSaved: ({ widget }) => widget.isSaved,
      id: ({ widget }) => widget.id,
      isPreviewing: ({ widget }) => widget.isPreviewing,
      userFilterValues: ({ widget }) => widget.userFilterValues,
      selectedWidget: ({ widget }) => widget.selectedWidget,
      widgetConfiguration: ({ widget }) => widget.widgetConfiguration,
    }),
    ...mapGetters('widget', ['formModel', 'formValid']),
  },
  methods: {
    ...mapMutations('widget', ['setStatus', 'setChartData', 'setPreviewData']),
    close() {
      this.form = this.$lodash.cloneDeep(initialForm)
      this.$emit('close')
    },

    handleDone() {
      this.$root.$emit(RootEvents.WIDGET_CLEAR_ALL)
    },

    async handleSave() {
      if (!this.formValid) return false
      this.loading = true
      let result
      if (this?.widgetConfiguration?.widgetId) {
        const updatePayload = this.formModel
        Object.assign(updatePayload, { version: this?.widgetConfiguration?.version ?? 1 })
        const { response } = await this.$async(
          dashboardService('widget').update(this.widgetConfiguration.widgetId, updatePayload),
        )
        result = response
      } else {
        const { response } = await this.$async(
          dashboardService('widget').store(this.formModel),
        )
        result = response
      }
      if (!result.error) {
        const widgetId = this?.widgetConfiguration?.widgetId ?? result.data
        this.setStatus({ val: true, id: widgetId })
        await this.handlePreviewData()
      }

      this.loading = false
    },

    async handlePreviewData() {
      if (this.selectedWidget === 'TABLE' && this.isPreviewing) {
        this.$root.$emit(RootEvents.CALL_API, 0)
      } else {
        const widgetResponse = await this.$async(
          dashboardService('widget').getWidgetData(this.id, {
            userFilterValues: this.userFilterValues,
          }),
        )
        this.setPreviewData(widgetResponse.response.data)
      }
    },
  },
}
</script>

<style lang="scss">
.widget-chart-container {
  width: 100%;
  .widget-form-container {
    .column__content {
      padding: 15px;
      margin-top: 20px;
      border-radius: 8px;
      background: var(--colour--card-bg);
      $approx-nav-footer-height: 210px;
      height: calc(90vh - #{$approx-nav-footer-height});
      overflow: auto;
    }
  }
}
</style>
