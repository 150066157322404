<template>
    <div>
        <div class="column__content tw-overflow-auto tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-relative" style="background-color: white;">
            <div class="search__actions tw-flex tw-justify-end tw-mb-2">
                <h1 class="tw-font-bold tw-text-sm w-100">{{ $t(isEditMode?'Edit Widget (Advanced Data Source)' : 'New Widget (Advanced Data Source)') }}</h1>
                <b-button
                    id="search__clear-all"
                    variant="outline-secondary"
                    class="mr-1"
                    @click="handleClearClick"
                >
                    <span class="align-middle">{{ allowPreview? $t('Back') : $t('Cancel') }}</span>
                </b-button><b-button
                    v-if="allowPreview || hasNoChanges()"
                    id="search__edit"
                    :class="{ 'icon-disabled': !$can('Update', 'Data_Source') }"
                    variant="primary"
                    style="margin-right: 12px;"
                    @click="previewClicked"
                >
                    <span class="align-middle">{{ $t('Preview') }}</span>
                </b-button>
                <b-button
                    v-if="!allowPreview"
                    id="search__edit"
                    :class="{ 'icon-disabled': !$can('Update', 'Data_Source') }"
                    variant="primary"
                    @click="saveButtonClicked"
                    :disabled="isEditMode? hasNoChanges() : isInputIncomplete()"
                >
                    <span class="align-middle">{{ $t('Save') }}</span>
                </b-button>
            </div>
            <div class="column__content tw-mt-5">
                <p class="tw-text-xs">{{ $t('Select Widget type') }}</p>
                <b-form-radio-group
                    id="skin-radio-group"
                    class="tw-mt-2"
                    :disabled="allowPreview"
                    v-model="selectedWidgetType"
                    name="skin"
                    :options="skinOptions">
                    <b-form-radio
                        v-for="field in widgetOptions"
                        :key="field.key"
                        field="selectedUser"
                        :value="field"
                        class="tw-my-2 custom-control custom-control-inline custom-radio"
                    >
                        {{ $t(field.label) }}
                        </b-form-radio>
                </b-form-radio-group>
            </div>
        </div>
        <div class="column__content tw-overflow-auto tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-relative" style="background-color: white;">
            <div v-if="selectedWidgetType.id === 'KPI'" class="kpi-cols">
              <h2 class="tw-font-bold tw-text-sm">{{ $t('Values') }}</h2>
              <ProzessSelect
                  v-model="selectedColumn[0]"
                  :options="columnOptions"
                  :disabled="allowPreview"
                  />

              <br/>
              <h2 class="tw-font-bold tw-text-sm">{{ $t('Aggregation') }}</h2>
              <ProzessSelect
                  v-model="selectedAggregation"
                  :options="aggregationOptions"
                  :disabled="allowPreview"
                  />
            </div>

            <div v-if="selectedWidgetType.id === 'BAR'" class="bar-option">
              <h2 class="tw-font-bold tw-text-sm">{{ $t('Data') }}</h2>
              <ProzessSelect
                  v-model="selectedBarOption"
                  :options="barOptions"
                  :disabled="allowPreview"
                />
                <br/>
            </div>

            <div v-if="selectedWidgetType.id === 'PIE'
                    || selectedWidgetType.id === 'LINE'
                    || (selectedWidgetType.id === 'BAR' && selectedBarOption.id === 'BY_ROW__')
                    || selectedWidgetType.id === 'TREE'
                    " class="kpi-cols">
              <h2 class="tw-font-bold tw-text-sm">{{ $t('Values') }}</h2>

              <b-form-group
                :label="selectedWidgetType.id === 'LINE'? $t('X-Axis') : $t('Label')">
              <ProzessSelect
                  v-model="selectedColumn[0]"
                  :options="columnOptions"
                    :disabled="allowPreview"
                  />
              </b-form-group>

              <b-form-group
              :label="selectedWidgetType.id === 'LINE'? $t('Y-Axis') : $t('Count')">
              <ProzessSelect
                  v-model="selectedColumn[1]"
                  :options="columnOptions"
                    :disabled="allowPreview"
                  />
              </b-form-group>

              <div v-for="(col, i) in selectedColumn" :key="i">
                <b-form-group
                v-if="i>1">
                <div class="d-flex">
                <ProzessSelect
                    class="w-100"
                    v-model="selectedColumn[i]"
                    :options="columnOptions"
                    :clearable="false"
                    />
                  <feather-icon
                    :id="`delete-icon--${i}`"
                    :class="{
                      'icon-disabled': isRequired,
                    }"
                    style="margin-top: 8px"
                    class="mx-1 tw-cursor-pointer tw-text-dark"
                    icon="TrashIcon"
                    size="16"
                    @click="deleteSelected(i)"
                  />
                </div>
                </b-form-group>
              </div>
            </div>
            <b-button
              id="add-col"
              variant="primary"
              @click="addColumn"
              v-if="(this.selectedWidgetType.id === 'BAR' && selectedBarOption.id === 'BY_ROW__') || this.selectedWidgetType.id === 'LINE'"
              >
              <span class="align-middle">{{ $t('Add') }}</span>
            </b-button>

        </div>
        <div v-if=showPreview class="column__content tw-overflow-auto tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-relative" style="background-color: white;">
            <h2 class="tw-font-bold tw-text-sm">{{ $t('Preview') }}</h2>
            <WidgetAdvancedPreview
               :widgetUuid=createdWidgetUuid
            />
       </div>
    </div>
  </template>
  <script>
import advancedSearchService from '@/services/advancedSearch'
import { dashboardService } from '@/services/dashboard'
import ProzessSelect from '@/@core/components/ProzessSelect.vue'
import WidgetAdvancedPreview from '@/components/Widgets/WidgetAdvancedPreview.vue'
import RootEvents from '@/constants/rootEvents'

const defaultStandardEntities = [
  {
    text: 'None',
    value: 'false',
    isCompanyContact: false,
  },
  {
    text: 'Company Contact',
    value: 'true',
    isCompanyContact: true,
  },
]
export default {

    components: {
    ProzessSelect,
    WidgetAdvancedPreview,
  },
  props: {
        form: {
          type: Object,
          required: true,
        },
        isEditMode: {
          type: Boolean,
          required: true,
        },
    },
  watch: {
    selectedWidgetType: {
      handler() {
        if (!this.isEditMode || (this.isEditMode && this.selectedWidgetType.id !== this.baseWidget.widgetConfiguration.type)) {
          this.selectedColumn = [{}, {}]
          this.selectedBarOption = {}
        } else if (this.isEditMode && this.selectedWidgetType.id === this.baseWidget.widgetConfiguration.type) {
          this.resetValuesToBaseWidget()
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
        allowPreview: false,
        showPreview: false,
        createdWidgetUuid: null,
        datasource: {},
        baseWidget: {},
        selectedColumn: [{}, {}],

        selectedBarOption: {},
        barOptions: [
          {
            id: 'BY_COLUMN__',
            label: 'By column',
          },
          {
            id: 'BY_ROW__',
            label: 'By row',
          },
        ],

        columnOptions: [],
        selectedAggregation: {},
        aggregationOptions: [
          {
            label: 'Sum',
            id: 'SUM',
          },
          {
            label: 'Count',
            id: 'COUNT',
          },
          {
            label: 'Min',
            id: 'MIN',
          },
          {
            label: 'Max',
            id: 'MAX',
          },
          {
            label: 'Average',
            id: 'AVG',
          },
        ],
        selectedWidgetType: '',
        widgetOptions: [
            {
                label: 'KPI',
                id: 'KPI',
            },
            {
                label: 'Table',
                id: 'TABLE',
            },
            {
                label: 'Bar graph',
                id: 'BAR',
            },
            {
                label: 'Line graph',
                id: 'LINE',
            },
            {
                label: 'Pie Chart',
                id: 'PIE',
            },
            {
                label: 'Treemap Chart',
                id: 'TREE',
            },
        ],
    }
  },

 async mounted() {
    if (this.isEditMode) {
      const baseWidgetResponse = await dashboardService('widget').getWidget(this.form.widget.widgetId)
      this.baseWidget = JSON.parse(JSON.stringify(baseWidgetResponse.data))
      this.selectedWidgetType = { id: this.form.widget.widgetConfiguration.type }
      // console.log('lll', this.widgetOptions.filter(w => w.id === this.form.widget.widgetConfiguration.type)[0].label)
      this.selectedWidgetType.label = this.widgetOptions.filter(w => w.id === this.form.widget.widgetConfiguration.type)[0].label
      this.dataSource = this.form.dataSource
      this.createdWidgetUuid = this.form.widget.widgetId
      console.log('base', this.baseWidget)
      if (this.baseWidget.widgetConfiguration.type === 'KPI') {
        this.selectedAggregation = this.aggregationOptions.filter(a => a.id === this.baseWidget.widgetConfiguration.singleNumberConfiguration.aggregationFunction)[0]
      }
      this.resetValuesToBaseWidget()
    } else {
      const { data } = await dashboardService('datasource').getOne(this.form.dataSource.uuidKey)
      this.dataSource = data
    }
    await this.getColumns()
  },
  methods: {
    resetValuesToBaseWidget() {
      if (this.form.widget.widgetConfiguration.type === 'BAR') {
        if (this.form.widget.widgetConfiguration.countLabelConfiguration.labelField.fieldKey === 'BY_COLUMN__') {
          this.selectedBarOption = { id: 'BY_COLUMN__', label: 'By column' }
        } else {
          this.selectedBarOption = { id: 'BY_ROW__', label: 'By row' }
        }
      }

      if (this.form.widget.widgetConfiguration.type === 'KPI') {
          this.selectedColumn = [
            {
              key: this.form.widget.widgetConfiguration.singleNumberConfiguration.field.fieldKey,
              label: this.form.widget.widgetConfiguration.singleNumberConfiguration.field.fieldKey,
            },
          ]
          this.selectedAggregation = this.aggregationOptions.filter(a => a.id === this.form.widget.widgetConfiguration.singleNumberConfiguration.aggregationFunction)[0]
      } else if (['BAR', 'LINE', 'PIE', 'TREE'].includes(this.form.widget.widgetConfiguration.type)) {
          const selectedCol = [
            {
              key: this.form.widget.widgetConfiguration.countLabelConfiguration.labelField.fieldKey,
              label: this.form.widget.widgetConfiguration.countLabelConfiguration.labelField.fieldKey,
            },
            // {
            //   key: this.form.widget.widgetConfiguration.countLabelConfiguration.countConfigurations[0].countField.fieldKey,
            //   label: this.form.widget.widgetConfiguration.countLabelConfiguration.countConfigurations[0].countField.fieldKey,
            // },
          ]
          this.form.widget.widgetConfiguration.countLabelConfiguration.countConfigurations.forEach((ite, i) => {
            selectedCol.push({
              key: ite.countField.fieldKey,
              label: ite.countField.fieldKey,
            })
          })
          this.selectedColumn = selectedCol
      }
      this.showPreview = false
    },
    deleteSelected(index) {
      this.selectedColumn.splice(index, 1)
    },
    async getColumns() {
      const payload = {
        query: this.dataSource.query,
      }
      const res = await advancedSearchService.runUnsavedQueryAdvanced(payload)
      const resultFirst = Object.keys(res.data.pageItems[0])
      this.columnOptions = Object.values(resultFirst).map(e => ({ key: e, label: e }))
    },
    handleClearClick() {
      this.$root.$emit(RootEvents.WIDGET_CLEAR_ALL)
    },
    addColumn() {
      this.selectedColumn.push({})
    },
    previewClicked() {
      this.showPreview = !this.showPreview
    },
    isInputIncomplete() {
      let isIncomplete = false
      if (this.selectedWidgetType.id == null) {
        isIncomplete = true
      } else if (this.selectedWidgetType.id === 'KPI' && (this.selectedAggregation == null || this.selectedAggregation === {}) && (this.selectedColumn[0] == null || this.selectedColumn[0].key == null)) {
        isIncomplete = true
      } else if (this.selectedWidgetType.id === 'BAR' && this.selectedBarOption.id === 'BY_COLUMN__') {
        return false
      } else if (['BAR', 'LINE', 'PIE', 'TREE'].includes(this.selectedWidgetType.id) && ((this.selectedColumn[0] == null || this.selectedColumn[0].key == null) || (this.selectedColumn[1] == null || this.selectedColumn[1].key == null))) {
        isIncomplete = true
      }
      return isIncomplete
    },
    hasNoChanges() {
      const currentPayload = this.buildWidgetPayload()
      return (currentPayload.description === this.baseWidget.description)
          && (currentPayload.name === this.baseWidget.name)
          && (currentPayload.widgetConfiguration.type === this.baseWidget.widgetConfiguration.type)
          && (currentPayload.widgetConfiguration.datasourceUuidKey === this.baseWidget.widgetConfiguration.datasourceUuidKey)
          && (JSON.stringify(currentPayload.widgetConfiguration.singleNumberConfiguration) === JSON.stringify(this.baseWidget.widgetConfiguration.singleNumberConfiguration))
          && (JSON.stringify(currentPayload.widgetConfiguration.countLabelConfiguration) === JSON.stringify(this.baseWidget.widgetConfiguration.countLabelConfiguration))
    },
    buildWidgetPayload() {
      const payload = {
        description: this.form.description,
        groupBy: null,
        name: this.form.name,
        widgetConfiguration: {
         type: this.selectedWidgetType.id,
         datasourceUuidKey: this.form.dataSource.uuidKey,
         filters: [],
         customizableFilterFields: [],
         groupBy: [],
         singleNumberConfiguration: null,
         countLabelConfiguration: null,
         tableSorting: null,
        },
      }
      if (this.selectedWidgetType.id === 'KPI') {
        payload.widgetConfiguration.singleNumberConfiguration = {
            aggregationFunction: this.selectedAggregation.id, // "SUM|AVG|MAX|MIN|COUNT"
            field: {
                schemaKey: '',
                tableKey: '',
                fieldKey: this.selectedColumn[0].key,
                customField: false,
            },
         }
      } else if (this.selectedWidgetType.id === 'BAR' && this.selectedBarOption.id === 'BY_COLUMN__') {
        const cConfig = [{
              description: '',
              countAggregationFunction: '',
              countField: {
                schemaKey: '',
                tableKey: '',
                fieldKey: '',
                customField: false,
              },
            }]
        payload.widgetConfiguration.countLabelConfiguration = {
          countConfigurations: cConfig,
          labelField: {
            schemaKey: '',
            tableKey: '',
            fieldKey: 'BY_COLUMN__',
            customField: false,
          },
        }
      } else if (this.selectedWidgetType.id === 'PIE' || this.selectedWidgetType.id === 'LINE'
                || this.selectedWidgetType.id === 'BAR'
                || this.selectedWidgetType.id === 'TREE'
      ) {
        const cConfig = []
        this.selectedColumn.forEach((col, i) => {
          if (i > 0 && col.key != null) {
          cConfig.push({
              description: '',
              countAggregationFunction: 'COUNT', // "SUM|AVG|MAX|MIN|COUNT"
              countField: {
                schemaKey: '',
                tableKey: '',
                fieldKey: this.selectedColumn[i].key,
                customField: false,
              },
            })
          }
        })
        payload.widgetConfiguration.countLabelConfiguration = {
          countConfigurations: cConfig,
          labelField: {
                schemaKey: '',
                tableKey: '',
                fieldKey: this.selectedColumn[0].key,
                customField: false,
          },
         }
      }
      return payload
    },
    async saveButtonClicked() {
      const payload = this.buildWidgetPayload()
      if (this.isEditMode) {
        payload.version = this.baseWidget.version
        const updateResponse = await dashboardService('widget').updateAdvancedWidget(this.form.widget.widgetId, payload)
        this.allowPreview = true
        this.createdWidgetUuid = this.form.widget.widgetId
      } else {
        const res = await dashboardService('widget').saveAdvancedWidget(payload)
        if (res.data !== null) {
          this.allowPreview = true
          this.createdWidgetUuid = res.data
        }
      }
    },
  },
}
  </script>

  <style lang="scss">
  </style>
